<!--设备运维：设备列表-->
<template>
  <div class="app-container">
    <!--页头-->
    <div class="page-container">
      <div class="section">
        <div class="control-box">
          <div class="cb-left">
            <h1 style="font-size: 20px;">提报原因</h1>
          </div>
        </div>
        <div class="table-box">
          <el-tabs v-model="activeName" type="card" @tab-click="getDeviceListById">
            <el-tab-pane label="异常待机" name="first">
              <div class="control-box">
                <!--添加按钮-->
                <div class="cb-left">
                  <el-input
                      v-model="form.name"
                      placeholder="搜索原因"
                      style="width: 200px; margin-left: 10px"
                      class="control-item"
                      clearable
                      @keyup.enter.native="getDevicePrintTypeList(1)"
                  />

                  <el-button
                      class="control-item"
                      type="primary"
                      icon="el-icon-search"
                      @click="getDevicePrintTypeList(1)"
                  >
                    搜索
                  </el-button>
                </div>
                <div class="cb-right">
                  <el-button
                      class="control-item"
                      type="primary"
                      style="margin-left: 10px;"
                      icon="el-icon-edit"
                      @click="toAdd(1)"
                  >
                    增加
                  </el-button>
                </div>
              </div>
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="abnormal"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <!--序号-->
                  <el-table-column label="序号" type="index" align="center" width="80">
                  </el-table-column>
                  <el-table-column label="原因选填" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.value }}
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.teamState === 1 ? '平台' : '企业' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="是否启用" align="center">
                    <template slot-scope="scope">
                      <el-switch
                          v-model="scope.row.state === 1 ? true : false"
                      @change="handleSwitch(scope.row)"
                      >
                      </el-switch>
                    </template>
                  </el-table-column>

                  <!--操作-->
                  <el-table-column label="操作" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" v-if="scope.row.teamState !== 1" size="mini" @click="updateDevice(scope.row,1)">
                        编辑
                      </el-button>
                      <el-button
                          v-if="scope.row.teamState !== 1"
                          type="primary"
                          plain
                          size="mini"
                          icon="el-icon-delete"
                          @click="deleteDevice(scope.row,1)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="form.count"
                    :total="totalCount"
                    :current-page="form.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="定期更换" name="second">
              <div class="control-box">
                <!--添加按钮-->
                <div class="cb-left">
                  <el-input
                      v-model="replacement.name"
                      placeholder="搜索原因"
                      style="width: 200px; margin-left: 10px"
                      class="control-item"
                      clearable
                      @keyup.enter.native="getDevicePrintTypeList(2)"
                  />

                  <el-button
                      class="control-item"
                      type="primary"
                      icon="el-icon-search"
                      @click="getDevicePrintTypeList(2)"
                  >
                    搜索
                  </el-button>
                </div>
                <div class="cb-right">
                  <el-button
                      class="control-item"
                      type="primary"
                      style="margin-left: 10px;"
                      icon="el-icon-edit"
                      @click="toAdd(2)"
                  >
                    增加
                  </el-button>
                </div>
              </div>
              <!--表格-->
              <div class="table-box">
                <el-table
                    :data="replacementData"
                    border
                    highlight-current-row
                    style="width: 100%"
                >
                  <!--序号-->
                  <el-table-column label="序号" type="index" align="center" width="80">
                  </el-table-column>
                  <el-table-column label="原因选填" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.value }}
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.teamState === 1 ? '平台' : '企业' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="是否启用" align="center">
                    <template slot-scope="scope">
                      <el-switch
                          v-model="scope.row.state === 1 ? true : false"
                          @change="handleSwitch(scope.row)"
                      >
                      </el-switch>
                    </template>
                  </el-table-column>

                  <!--操作-->
                  <el-table-column label="操作" header-align="center" align="center" width="240">
                    <template slot-scope="scope">
                      <el-button type="primary" v-if="scope.row.teamState !== 1" size="mini" @click="updateDevice(scope.row,2)">
                        编辑
                      </el-button>
                      <el-button
                          v-if="scope.row.teamState !== 1"
                          type="primary"
                          plain
                          size="mini"
                          icon="el-icon-delete"
                          @click="deleteDevice(scope.row, 2)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!--分页-->
              <div class="pagination-box">
                <el-pagination
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="replacement.count"
                    :total="replacementCount"
                    :current-page="replacement.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 添加编辑 -->
    <el-dialog
        :visible.sync="daoruVal"
        :title="title"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :append-to-body="false"
        width="550px"
    >
      <div>
        <el-form :model="ruleForm" :rules="rules" label-position="left" ref="ruleForm1" label-width="70px"
                 class="demo-ruleForm">
          <el-form-item label="原因" prop="value">
            <el-input v-model="ruleForm.value" maxlength="20" placeholder="请填写原因" ></el-input>
          </el-form-item>
<!--          <el-form-item label="状态" prop="state">-->
<!--            <el-select-->
<!--                v-model="ruleForm.state"-->
<!--                clearable-->
<!--                placeholder="请选择"-->
<!--                style="width: 100%"-->
<!--            >-->
<!--              <el-option-->
<!--                  v-for="item in state"-->
<!--                  :key="item.type"-->
<!--                  :label="item.label"-->
<!--                  :value="item.type"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="daoruVal = false">取 消</el-button>
          <el-button v-if="title === '添加'" v-loading="loading" type="primary" @click="handleAddConfirm">确 定</el-button>
          <el-button v-if="title === '编辑'" v-loading="loading" type="primary" @click="handleAddConfirmEdit">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from '@/api';
import { manageOauth } from '@/utils/validate';

export default {
  data() {
    return {
      abnormal: [],
      replacementData: [],
      loading:false,
      title: '添加',
      type: 1,
      daoruVal: false,
      form: {
        page: 1,
        count: 10,
        name: '',
        type: 13,
      },
      replacement: {
        page: 1,
        count: 10,
        name: '',
        type: 14
      },
      state: [
        {
          label:'启用',
          type: 1
        },
        {
          label:'不启用',
          type: 0
        }
      ],
      totalCount: 0,
      replacementCount: 0,
      activeName: 'first',
      ruleForm: {
        value: '',
        type: null,
        teamId: '',
        id: ''
      },
      rules: {
        value: [
          {
            required: true,
            message: '请填写原因',
            trigger: 'blur'
          },
          { max: 12, message: '长度在 12 个字符内', trigger: 'blur' },
        ],
        state: [
          {
            required: true,
            message: '请选择状态',
            trigger: 'blur'
          }
        ]
      },
    };
  },
  computed: {
  },
  created() {
    this.getDevicePrintTypeList(1);
    this.getDevicePrintTypeList(2);
  },

  methods: {
    getDevicePrintTypeList(type) {
      API.abnormalDataList(type === 1 ? this.form : this.replacement).then(res => {
        console.log(res)
        if (type === 1) {
          this.abnormal = res.message.data.list
          this.totalCount = res.message.data.totalCount
        } else  {
          this.replacementData = res.message.data.list
          this.replacementCount = res.message.data.totalCount
        }
      })
    },
    back() {
      this.$router.go(-1);
    },
    // 弹窗确定
    handleAddConfirm() {
      if (this.type === 1) {
        this.ruleForm.type = 13
      } else {
        this.ruleForm.type = 14
      }
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          API.abnormalDataAdd({id: this.ruleForm.id,type:this.ruleForm.type,teamId:this.ruleForm.teamId,value:this.ruleForm.value}).then(res => {
            console.log(res.message.success)
            this.getDevicePrintTypeList(1);
            this.getDevicePrintTypeList(2);
            if (res.message.success) {
              this.$message({
                message: '添加成功',
                type: 'success',
                duration: 2000,
              });
              this.daoruVal = false
            } else {
              this.$message({
                message: res.message.message,
                type: 'error',
                duration: 2000,
              });
            }

          })
        }
      })
    },
    handleAddConfirmEdit() {
      if (this.type === 1) {
        this.ruleForm.type = 13
      } else {
        this.ruleForm.type = 14
      }
      this.$refs['ruleForm1'].validate((valid) => {
        if (valid) {
          API.abnormalDataAdd(this.ruleForm).then(res => {
            console.log(res.message.success)
            this.getDevicePrintTypeList(1);
            this.getDevicePrintTypeList(2);
            if (res.message.success) {
              this.$message({
                message: '编辑成功',
                type: 'success',
                duration: 2000,
              });
              this.daoruVal = false
            }

          })
        }
      })
    },
    handleSwitch(v) {
      if (v.state === 0) {
        v.state = 1
        API.abnormalDataState(v).then(res => {
          if (res.message.success) {
            this.$message({
              message: '启用成功',
              type: 'success',
              duration: 2000,
            });
          }
        })
      } else {
        v.state = 0
        API.abnormalDataState(v).then(res => {
          if (res.message.success) {
            this.$message({
              message: '关闭成功',
              type: 'success',
              duration: 2000,
            });
          }
        })
      }
    },
    getDeviceListById(tab) {
      this.form.page = 1
      this.replacement.page = 1
      this.getDevicePrintTypeList(1);
      this.getDevicePrintTypeList(2);
      console.log(tab.name)
    },
    deleteDevice(val,type) {
      this.$confirm('确认删除原因, 是否继续?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        API.abnormalDataDelete(val.id).then(res => {
          console.log(res)
          if (res.message.success) {
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 2000,
            });
            this.getDevicePrintTypeList(1);
            this.getDevicePrintTypeList(2);
          }
        })
      });
    },
    toAdd(type) {
      this.ruleForm.value = ''
      this.ruleForm.id = ''
      this.ruleForm.type = ''
      this.title = '添加'
      this.daoruVal = true
      this.$nextTick(() => {
        this.$refs.ruleForm1.clearValidate();
      });
      this.type = type
    },
    updateDevice(device,type) {
      this.title = '编辑'
      this.type = type
      this.$nextTick(() => {
        this.$refs.ruleForm1.clearValidate();
      });
      this.ruleForm = JSON.parse(JSON.stringify(device))
      this.daoruVal = true
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.getDevicePrintTypeList(1);
    },
    handleCurrentChange(val) {
      this.form.page = val ;
      this.getDevicePrintTypeList(1);
    },
    handleSizeChange2(val) {
      this.replacement.count = val;
      this.getDevicePrintTypeList(2);
    },
    handleCurrentChange2(val) {
      this.replacement.page = val ;
      this.getDevicePrintTypeList(2);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .dialog-footer {
  text-align: right;
}
</style>
